<template>

<div v-if="!contactIsMember" class="membership-app account-section-main">
    <h2>{{ this.contactIsMember ? 'Your' : 'Apply for' }} Membership</h2>

    <div class="application-progress">
        <div class="application-step" :class="{ 'active': membershipPane == 0, 'completed': membershipPane > 0 }" @click="updateMembershipPane(0)">Membership &nbsp;&nbsp;</div>
		<div class="arrow-divider" :class="{ 'active': membershipPane == 0, 'completed': membershipPane > 0, 'double-completed': membershipPane > 1 }"></div>
        <div class="application-step" :class="{ 'active': membershipPane == 1, 'completed': membershipPane > 1 }" @click="updateMembershipPane(1)">Application &nbsp;&nbsp;</div>
		<div class="arrow-divider" :class="{ 'active': membershipPane == 1, 'completed': membershipPane > 1, 'double-completed': membershipPane > 2 }"></div>
		<div class="application-step" :class="{ 'active': membershipPane == 2, 'completed': membershipPane > 2 }" @click="updateMembershipPane(2)">Terms &nbsp;&nbsp;</div>
        <div class="arrow-divider" :class="{ 'active': membershipPane == 2, 'completed': membershipPane > 2 }"></div>
		<div class="application-step" :class="{ 'active': membershipPane == 3, 'completed': membershipPane > 3 }" @click="updateMembershipPane(3)">Complete &nbsp;&nbsp;</div>
    </div>

    <div v-if="membershipPane == 0" class="membership-page membership-main">
		<div v-html="content.MEMBERSHIP_DESCRIPTION"></div>
		<button class="button action" @click="updateMembershipPane(1)">Next</button>
	</div>

    <div class="apply membership-main" v-if="membershipPane == 1">

		<div v-if="contactMessage" class="message">{{ contactMessage }}</div>

		<div v-if="Object.keys(memberships).length > 1" class="form-pair column">
			<label-required label="Which membership are you applying for?" :condition="membershipId" />
			<select v-model="membershipId">
				<option value="">Select...</option>
				<option v-for="(membership, membership_id) in memberships" :value="membership_id">
					{{ membership.description }} (${{ membership.price }} / {{ $caplitalizeWords(membership.subscription_frequency_code) }})
				</option>
			</select>
		</div>
		<div v-else class="form-group">
			<h2>Applying for Membership</h2>
			<p>{{ Object.values(memberships)[0].description }} (${{ Object.values(memberships)[0].price }} / {{ $caplitalizeWords(Object.values(memberships)[0].subscription_frequency_code) }})</p>
		</div>

		<h3>Name and Address</h3>
		<div class="three-columns">
			<div class="form-pair">
				<label-required label="First Name" :condition="contact.first_name" />
				<input ref="first_name" id="first_name" type="text" v-model="contact.first_name">
			</div>
			<div class="form-pair">
				<label for="middle_name">Middle Name</label>
				<input ref="middle_name" id="middle_name" type="text" v-model="contact.middle_name">
			</div>
			<div class="form-pair">
				<label-required label="Last Name" :condition="contact.last_name" />
				<input ref="last_name" id="last_name" type="text" v-model="contact.last_name">
			</div>
		</div>
		<div class="form-pair">
			<label-required label="Address Line 1" :condition="contact.address_1" />
			<input ref="address_1" id="address_1" type="text" v-model="contact.address_1">
		</div>
		<div class="form-pair">
			<label for="address_2">Address Line 2</label>
			<input ref="address_2" id="address_2" type="text" v-model="contact.address_2">
		</div>
		<div class="three-columns">
			<div class="form-pair">
				<label-required label="City" :condition="contact.city" />
				<input ref="city" id="city" type="text" v-model="contact.city">
			</div>
			<div class="form-pair">
				<label-required label="State" :condition="contact.state" />
				<state-select v-model="contact.state" ref="state" id="state" :selectedState="contact.state" :showApo="true" :showProvinces="true" placeHolder="State / Province..." :showAbbr="true" />
			</div>
			<div class="form-pair">
				<label-required label="Zip / Postal Code" :condition="contact.zip_code" />
				<input ref="zip_code" id="zip_code" type="text" v-model="contact.zip_code">
			</div>
		</div>

		<h3>Contact Info</h3>
		<div class="two-columns">
			<div class="form-pair">
				<label-required label="Phone Number" :condition="contact.phone_number" />
				<input ref="phone_number" id="phone_number" type="text" v-model="contact.phone_number">
			</div>
			<div class="form-pair">
				<label-required label="Email Address" :condition="contact.email_address" />
				<input ref="email_address" id="email_address" type="text" v-model="contact.email_address">
			</div>
		</div>
		<div class="two-columns">
			<div class="form-pair">
				<label-required label="Date of Birth" :condition="contact.birthdate" />
				<input ref="birthdate" id="birthdate" type="date" v-model="contact.birthdate">
			</div>
			<div class="form-pair">
				<label-required label="Driver License Number" :condition="contact.drivers_license_number" />
				<input ref="drivers_license_number" id="drivers_license_number" type="text" v-model="contact.drivers_license_number">
			</div>
		</div>

		<h3>Emergency Contact</h3>
		<div class="two-columns">
			<div class="form-pair">
				<label-required label="Emergency Contact Name" :condition="emergencyContact.emergency_name" />
				<input ref="emergency_name" id="emergency_name" type="text" v-model="emergencyContact.emergency_name">
			</div>
			<div class="form-pair">
				<label-required label="Phone Number" :condition="emergencyContact.emergency_phone" />
				<input ref="emergency_phone" id="emergency_phone" type="text" v-model="emergencyContact.emergency_phone">
			</div>
			<div class="form-pair">
				<label-required label="Relationship" :condition="emergencyContact.emergency_relationship" />
				<input ref="emergency_relationship" id="emergency_relationship" type="text" v-model="emergencyContact.emergency_relationship">
			</div>
		</div>

		<div class="controls">
			<button class="button primary" @click="updateMembershipPane(0)">Previous</button>
			<button class="button action" @click="updateMembershipPane(2)">Next</button>
		</div>

		<div v-if="contactMessage" class="message">{{ contactMessage }}</div>

	</div>

	<div class="terms membership-main" v-if="membershipPane == 2">
		<div v-if="termsMessage" class="message">{{ termsMessage }}</div>
		<!-- <h2>Terms</h2> -->
		<div v-html="content.MEMBERSHIP_TERMS"></div>
		<div class="form-pair">
			<label-required label="Your Initials" :condition="termsInitials" /> 
			<input type="text" ref="termsInitials" id="termsInitials" v-model="termsInitials" style="width: 5em;">
		</div>
		<div class="controls">
			<button class="button primary" @click="updateMembershipPane(1)">Previous</button>
			<button class="button action" @click="updateMembershipPane(3)">Next</button>
		</div>

		<div v-if="termsMessage" class="message">{{ termsMessage }}</div>
	</div>

	<div class="membership-complete membership-main" v-if="membershipPane == 3">
		<!-- <h3>Complete your application:</h3> -->
		<p v-if="content.MEMBERSHIP_APPLICATION_COMPLETE" v-html="content.MEMBERSHIP_APPLICATION_COMPLETE"></p>
		<p v-else>Your application info is complete!</p>
		<div class="form-pair">
			<label>Membership</label>
			<p class="detail-data">{{ membershipSelected.description }}</p>
		</div>
		<div class="form-pair">
			<label>Price</label>
			<p class="detail-data">${{ membershipSelected.price }}</p>
		</div>
		<h3 v-if="membershipSelected.price > 0" class="note">Add this membership to your cart and then proceed to checkout to complete the application process.</h3>
		<h3 v-else class="detail-data">There is no charge for this membership. Submit your application to complete the process.</h3>
		<div class="controls">
			<button class="button primary" @click="updateMembershipPane(2)">Previous</button>
			<button :class="['button', saving ? 'disabled' : 'action']" @click="submitApplication()">{{ membershipSelected.price > 0 ? 'Add To Cart' : 'Submit Application' }}</button>
		</div>
		<!-- <div v-if="completeMessage" class="message">{{ completeMessage }}</div> -->
	</div>

	<!-- <message class="message" :message="message" @transitionDone="message = null"></message> -->

    <div v-if="savingMessage" class="modal">
        <div class="inner">
            <h2>Membership Application</h2>
            <div class="content" style="font-size: 1.25em; text-align: center;">
                <p>{{ savingMessage }}</p>
                <i v-if="saving" class="fas fa-spinner fa-spin"></i>
            </div>
			<div v-if="!saving" class="controls">
				<button class="button neutral" @click="savingMessage = ''">Try Again</button>
			</div>
        </div>
    </div>

</div>

<div v-else-if="member.membership" class="membership-list account-section-main">
    <h2>Your Membership</h2>
    <div class="membership-section columns two-one-columns">
        <div class="column">
			<h3>Membership Info</h3>
			<div class="form-pair">
				<label>Membership Type</label>
				<p class="detail-data">{{ member.membership.description }}</p>
			</div>

			<div class="form-pair">
					<label>Membership Number</label>
					<p class="detail-data">{{ member.membership.membership_number || 'N/A' }}</p>
				</div>

			<div class="two-columns">
				<div class="form-pair">
					<label>Membership Start</label>
					<p class="detail-data">{{ member.membership.start_date ? $moment(member.membership.start_date).format('MM/DD/YYYY') : 'N/A' }}</p>
				</div>
				<div class="form-pair">
					<label>Membership End</label>
					<p class="detail-data">{{ member.membership.end_date ? $moment(member.membership.end_date).format('MM/DD/YYYY') :  'N/A' }}</p>
				</div>
			</div>
			
			<div class="form-pair">
				<label>Range Discounts</label>
				<p v-if="member.membership.range_discount == '1.00'" class="detail-data">Member does not pay for range time.</p>
				<p v-else-if="member.membership.range_amount" class="detail-data">{{ 'Member pays ' + member.membership.range_amount + ' for range time.' }}</p>
				<p v-else-if="member.membership.range_discount > 0"  class="detail-data">Member gets a {{ member.membership.range_discount * 100 }}% discount on range time.</p>
				<p v-else class="detail-data">Member pays full price for range time.</p>
			</div>

			<div v-if="false" class="form-pair">
				<label>Product Discounts</label>
				<p v-if="member.membership.product_discount" class="detail-data">Member gets a {{ member.membership.product_discount * 100 }}% discount on products.</p>
				<p v-else class="detail-data">Member does not get a discount on products.</p>
			</div>
        </div>

		<div class="column">
			<h3>Subscription Info</h3>
			<div class="form-pair">
				<label>Subscription Amount</label>
				<p class="detail-data">${{ member.subscription.amount }} / {{ $caplitalizeWords(member.membership.subscription_frequency_code) }}</p>
			</div>

			<div class="form-pair">
				<label class="full">Payment Method</label>
				<p class="detail-data">
					<span v-if="member.subscription.subscription_id">{{ member.subscription.prepaid ? 'Prepaid' : 'Credit Card' }}</span>
					<span v-else>None</span>
				</p>
			</div>

			<div v-if="member.subscription.subscription_id">
				<div class="form-pair">
					<label>Next Payment</label>
					<p class="detail-data">{{ member.subscription.next_payment_date }}</p>
				</div>
				<div v-if="!member.subscription.prepaid" class="form-pair">
					<label>Card Status</label>
					<p v-if="member.subscription.expiration_year && member.subscription.expiration_year + '-' + member.subscription.expiration_month + '-30' < today" class="detail-data">Expired</p>
					<p v-else-if="member.subscription.expiration_year && member.subscription.expiration_year + '-' + member.subscription.expiration_month + '-30' < $moment(today).add(1, 'M').format('YYYY-MM-DD')" class="detail-data">Expires Soon</p>
					<p v-else-if="member.subscription.internal_use_only" class="detail-data">Payment Failed</p>
					<p v-else class="detail-data">Card is current</p>
				</div>
			</div>

			<div v-if="member.subscription.amount > 0 && !updateSubscription" class="controls">
				<button class="button action" @click="updateSubscription = true">Update Payment Info</button>
			</div>

		</div>

    </div>

	<div v-if="updateSubscription" class="membership-list account-section-main">
		<h3>Update Payment Info</h3>
		<div class="membership-section column columns two-one-columns" style="margin: 0 0 1em 0;">
			<div class="form-pair">
				<div v-if="member.subscription.placeholder_reference_number" style="margin: 0 0 1em 0;">
					<label>Current Card</label>
					<div><b>{{ member.subscription.placeholder_reference_number }}</b> Expiration <b>{{ member.subscription.placeholder_expiration_month }}/{{ member.subscription.placeholder_expiration_year }}</b></div>
				</div>
				<label>Name on Card</label>
				<input v-model="member.subscription.full_name" type="text" class="required">
				<label>Card Number</label>
				<!-- <input type="text" v-model="member.subscription.reference_number" class="required"> -->
				<input type="text" v-model="member.subscription.reference_number" v-cardformat:formatCardNumber class="required">

				<div class="three-columns">
					<div class="form-pair">
						<label>Expiration Month</label>
						<select v-model="member.subscription.expiration_month" class="required">
							<option value="">Select...</option>
							<option v-for="month in expirationMonths" :value="month.code">{{ month.code }}</option>
						</select>
					</div>
					<div class="form-pair">
						<label>Expiration Year</label>
						<select v-model="member.subscription.expiration_year" class="required">
							<option value="">Select...</option>
							<option v-for="year in expirationYears" :value="year">{{ year }}</option>
						</select>
					</div>
					<div class="form-pair">
						<label>Security Code</label>
						<div>
							<input type="text" v-model="member.subscription.cvv" class="required" style="width: 4em;">
							<div class="tooltip">
								<span class="tooltip-icon material-symbols-outlined" @click="showTooltip = !showTooltip">&#xe88e;</span>
								<div v-if="showTooltip" class="tooltip-content">On most cards, the 3-digit security code is on the back, to the right of the signature. On American Express cards, the 4-digit security code is on the front, near the top-right of the card number.</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>

		<div class="controls">
			<button :class="['button', saving ? 'disabled' : 'action']" @click="processUpatePayment()">Save Payment Info</button>
			<button class="button neutral" @click="updateSubscription = false">Cancel</button>
			<div v-if="paymentMessage" :class="['message', {error: !saving}]" style="display: inline-block;">{{ paymentMessage }}</div>
		</div>

	</div>

</div>
<div v-else>Loading...</div>

</template>


<script>
import Vue from 'vue'
import VueCardFormat from 'vue-credit-card-validation';
Vue.use(VueCardFormat);
import StateSelect from '@/components/StateSelect.vue'
import LabelRequired from '@/components/LabelRequired.vue'

export default {
	name: 'membership',
	props: ['contact_membership_id'],
	components: {
		'state-select': StateSelect,
		'label-required': LabelRequired
	},
	data() {
		return {
			loading: false,
			membershipPane: 0,
			userIsMember: false,
			contactIsMember: {},
			member: {},
			content: {},
			memberships: [],
			membershipId: null,
			contact: {
				first_name: null,
				last_name: null,
				birthdate: null,
				email_address: null,
				company_name: null,
				address_1: null,
				address_2: null,
				city: null,
				zip_code: null,
				phone_number: null,
				drivers_license_number: null
			},
			emergencyContact: {
				emergency_name: null,
				emergency_phone_number: null,
				emergency_relationship: null
			},
			message: null,
			contactMessage: null,
			termsMessage: null,
			terms: null,
			termsInitials: null,
			saving: false,
			savingMessage: '',
			completeMessage: '',
			listDetail: {},
			updateSubscription: false,
			expirationMonths: [
				{ code: "01", description: "January" },
				{ code: "02", description: "February" },
				{ code: "03", description: "March" },
				{ code: "04", description: "April" },
				{ code: "05", description: "May" },
				{ code: "06", description: "June" },
				{ code: "07", description: "July" },
				{ code: "08", description: "August" },
				{ code: "09", description: "September" },
				{ code: "10", description: "October" },
				{ code: "11", description: "November" },
				{ code: "12", description: "December" }
			],
			expirationYears: [],
			paymentMessage: '',
			showTooltip: false
		}
	},
	computed: {
		membershipSelected() {
			return this.memberships[this.membershipId]
		}
	},
	created() {
		this.today = this.$moment().format('YYYY-MM-DD')
		this.thisYear = new Date().getFullYear()
		for (let i = 0; i < 21; i++) {
			this.expirationYears.push(this.thisYear + i)
		}
		this.getMembership()
	},
	methods: {
		getMembership() {
			if (this.loading) { return }
			this.loading = true
			this.apiCall({ destination: 'get_item_member_frontend', data: { user_id: this.getUser.user_id } }).then(obj => {
				this.contactIsMember = obj.contact_membership_id
				this.member = obj.member
				
				if (this.member && this.member.subscription && this.member.subscription.reference_number) {
					this.member.subscription.placeholder_reference_number = this.member.subscription.reference_number
					this.member.subscription.reference_number = ''
					this.member.subscription.placeholder_expiration_month = this.member.subscription.expiration_month
					this.member.subscription.expiration_month = ''
					this.member.subscription.placeholder_expiration_year = this.member.subscription.expiration_year
					this.member.subscription.expiration_year = ''
				}
				this.loading = false
				if (!this.contactIsMember) {
					this.fetchMembershipApplication()
					this.fetchMemberships()
				}
			}).catch(err => {
				console.log(err)
			})
		},
		fetchMemberships() {
			this.apiCall({ destination: 'get_list_memberships' }).then(obj => {
				this.memberships = obj.memberships
				if (Object.keys(this.memberships).length > 1) {
					this.membershipId = '' // leave blank if more than one to force selection
				} else {
					this.membershipId = obj.default_membership_id
				}
			}).catch(err => {
				console.log(err)
			})
		},
		fetchMembershipApplication() {
			if (this.loading) { return }
			this.loading = true
			this.apiCall({ destination: 'get_item_membership_pages' }).then(obj => {
				this.content = obj.content
				this.contact = obj.contact
				if (obj.emergency_row && obj.emergency_row.emergency_name) {
					this.emergencyContact = obj.emergency_row
				}
				//this.contactIsMember = false
				this.loading = false
			}).catch(err => {
				console.log(err)
			})
		},
		fetchMembershipTerms() {
			this.apiCall({ destination: 'get_item_membership_terms' }).then(obj => {
				this.terms = obj.content
			}).catch(err => {
				console.log(err)
			})
		},
		updateMembershipPane(whereTo) {
			this.contactMessage = ''
			this.termsMessage = ''
			if (whereTo > 1 && !this.validateContact()) {
				return
			}
			if (whereTo > 2 && !this.validateMembershipTerms()) {
				return
			}
			this.membershipPane = whereTo
			window.scrollTo(0, 300);
		},
		validateContact() {
			this.contactMessage = ''
			if (!this.membershipId) {
				this.contactMessage = 'Please select which membership you are applying for.'
				return false
			}
			var error = false
			var requiredFields = [ 'first_name', 'last_name', 'address_1', 'city', 'state', 'zip_code', 'phone_number', 'email_address', 'birthdate', 'drivers_license_number' ]
			requiredFields.forEach(key => {
				if (!this.contact[key]) {
					this.$refs[key].classList.add('error')
					error = true
				}
			})
			if (!error) {
				requiredFields = [ 'emergency_name', 'emergency_phone', 'emergency_relationship' ]
				requiredFields.forEach(key =>{
					if (!this.emergencyContact[key]) {
						this.$refs[key].classList.add('error')
						error = true
					}
				})
			}
			if (error) {
				this.contactMessage = 'Please enter info for the highlighted fields.'
				return false
			}
			return true
		},
		validateMembershipTerms() {
			if (!this.termsInitials) {
				this.termsMessage = 'Please enter your initials below the terms.'
				return false
			}
			return true
		},
		submitApplication() {
			if (this.saving) { return }
			this.savingMessage = 'Saving contact info...'
			this.saving = true
			this.apiCall({ destination: 'save_item_contact', data: this.contact }).then(obj => {
				if (obj.status == 'success') {
					this.savingMessage = 'Creating membership...'
					this.apiCall({ destination: 'save_item_membership_frontend', data: { contact_id: obj.contact_id, membership_id: this.membershipId, emergency_contact: this.emergencyContact, terms_initials: this.termsInitials, create_membership: this.membershipSelected.price == 0 } }).then(obj => {
						this.completeMessage = obj.message
						if (obj.status == 'success') {
							// if membership has a price add to cart and proceed to checkout
							// NOTE: we're assuming customers can't CHANGE a membership in front-end, that this will always be a first-time purchase
							if (this.membershipSelected.price > 0) {
								this.savingMessage = 'Adding membership to cart...'
								this.apiCall({ destination: 'add_to_cart', data: { product_id: this.membershipSelected.product_id, quantity: 1 } }).then(obj => {
									this.saving = false
									if (obj.status == 'success') {
										this.$router.push('/cart')
									}
								}).catch(error => {
									console.log(error)
								})
							} else {
								this.getMembership()
								this.saving = false
							}
						} else {
							this.saving = false
							this.savingMessage = obj.message
						}
					}).catch(error => {
						console.log(error)
					})
				} else {
					this.saving = false
					this.savingMessage = obj.message
				}
			}).catch(error => {
				console.log(error)
			})
		},
		goToMyAccount() {
			this.$router.push('/myaccount')
		},
		processUpatePayment() {
			if (this.saving) { return }
			this.paymentMessage = ''
			if (!this.member.subscription.full_name) {
				this.paymentMessage = 'Please enter the full name on the card.'
				return
			}
			if (!this.member.subscription.reference_number || this.member.subscription.reference_number.length < 15) {
				this.paymentMessage = 'Please enter a valid card number.'
				return
			}
			if (!this.member.subscription.expiration_month) {
				this.paymentMessage = 'Please select an expiration month.'
				return
			}
			if (!this.member.subscription.expiration_year) {
				this.paymentMessage = 'Please select an expiration year.'
				return
			}
			if (!this.member.subscription.cvv) {
				this.paymentMessage = 'Please enter the card security code.'
				return
			}
			this.saving = true
			this.paymentMessage = 'Saving payment info...'
			let postObject = {
				contact_membership_id: this.member.membership.contact_membership_id,
				contact_id: this.member.contact.contact_id,
				subscription_id: this.member.subscription.subscription_id,
				subscription_frequency_id: this.member.membership.subscription_frequency_id,
				subscription_day: this.member.subscription.subscription_day,
				amount: this.member.membership.prices.web,
				vault_id: this.member.subscription.vault_id,
				prepaid: this.member.subscription.prepaid,
				cardholder_name: this.member.subscription.full_name,
				reference_number: this.member.subscription.reference_number.replace(/\D/g,''),
				expiration_month: this.member.subscription.expiration_month,
				expiration_year: this.member.subscription.expiration_year,
				cvv: this.member.subscription.cvv
			}
			this.apiCall({ destination: 'save_item_subscription_payment', data: postObject }).then(obj => {
				if (obj.status == 'success') {
					this.paymentMessage = 'Payment info saved!'
					setTimeout(() => {
						this.saving = false
						this.paymentMessage = ''
						this.updateSubscription = false
						this.getMembership()
					}, 2000)
				} else {
					this.saving = false
					this.paymentMessage = obj.message
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}
}
</script>

<style lang="scss">
.application-progress {
	display: grid;
	grid-auto-flow: column;
	background-color: #cecece;
	justify-content: start;
	.application-step {
		font-weight: bold;
		font-size: 1.25em;
		padding: 0.5em 1em;
		cursor: pointer;
	}
	.application-step.active {
		background-color: #343434;
		color: #fff;
	}
	.application-step.completed {
		background-color: #5d5d5d;
		color: #fff;
	}
	.arrow-divider {
		width: 0;
		height: 0;
		border-top: 25px solid transparent;
		border-left: 15px solid #cecece;
		border-bottom: 25px solid transparent;
	}
	.arrow-divider.active {
		border-left: 15px solid #343434;
	}
	.arrow-divider.completed {
		border-left: 15px solid #5d5d5d;
		background-color: #343434;
	}
	.arrow-divider.double-completed {
		background-color: #5d5d5d;
	}
}

@media (max-width: 960px) {
	.application-progress {
		grid-template-columns: 1fr 1em 1fr 1em;
		grid-auto-flow: unset;
		.application-step {
			font-size: 0.9em;
		}
	}
	.membership-main {
		.columns-2 {
			grid-template-columns: 1fr;
			h3 {
				grid-column: span 1;
			}
		}
		.form-pair {
			select { width: 100%; }
			input { width: 93.5%; }
		}
	}
}
@media (max-width: 600px) {
	.two-one-columns {
		grid-template-columns: 1fr;
	}
}

.membership-main {
    padding: 1em;
}

.membership-section {
	border-top: 1px solid grey;
	padding: 1em 0;
}

.message {
    grid-template-columns: 1fr;
	margin: 0.5em 0;
    border: none;
	&.error {
	    color: #de0000;
	}
}

.tooltip {
	display: inline-block;
	position: relative;
	.tooltip-icon {
		display: inline-block;
		margin: 0 0 0 0.5em;
		cursor: pointer;
	}
	.tooltip-content {
		position: absolute;
		background-color: #f9f9f9;
		color: #000;
		border: 1px solid #000;
		padding: 0.5em;
		border-radius: 0.25em;
		width: 35em;
		top: 2.25em;
		left: -32em;
		font-size: 0.8em;
	}
}

</style>
